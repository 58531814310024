import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import NewsPost from "../components/NewsPost/NewsPost";

import { GroupHeading } from '../styles/typography.css';
import { Container } from '../styles/Pages.css';

const News = ({data}) => (
  <>
    <SEO title="Comic Book Wiki: Latest news" keywords={[
      `comic books`,
      `news`,
      `database`
    ]} />
    <Layout page="category">
      <Container>
        <GroupHeading>Latest News</GroupHeading>
        <ul>
          {data.allWordpressPost.edges.map(post => (
            <NewsPost
              author={post.node.author.name}
              date={post.node.date}
              image={post.node.featured_media.localFile.childImageSharp.fixed}
              link={`/post/${post.node.slug}`}
              title={post.node.title}
              excerpt={post.node.excerpt}
            />
          ))}
        </ul>
      </Container>
    </Layout>
  </>
);

export default News;

export const query = graphql`
  query {
    allWordpressPost(
      sort: { fields: [date] order: DESC }
      filter: {categories: {elemMatch: {slug: {eq: "news"}}}}
    ) {
      edges {
        node {
          title
          excerpt
          slug
          author {
            name
          }
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
              url
            }
          }
        }
      }
    }
  }
`
